exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-de-semonbusiness-js": () => import("./../../../src/pages/a-propos-de-semonbusiness.js" /* webpackChunkName: "component---src-pages-a-propos-de-semonbusiness-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-references-clients-js": () => import("./../../../src/pages/references-clients.js" /* webpackChunkName: "component---src-pages-references-clients-js" */),
  "component---src-pages-sanity-post-language-news-index-js": () => import("./../../../src/pages/{SanityPost.language}/news/index.js" /* webpackChunkName: "component---src-pages-sanity-post-language-news-index-js" */),
  "component---src-pages-sanity-post-language-news-sanity-post-slug-current-js": () => import("./../../../src/pages/{SanityPost.language}/news/{SanityPost.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-post-language-news-sanity-post-slug-current-js" */),
  "component---src-pages-seminaires-et-congres-js": () => import("./../../../src/pages/seminaires-et-congres.js" /* webpackChunkName: "component---src-pages-seminaires-et-congres-js" */),
  "component---src-pages-teambuildings-js": () => import("./../../../src/pages/teambuildings.js" /* webpackChunkName: "component---src-pages-teambuildings-js" */)
}

